.auth-footer {
  margin-top: 10px;

  .MuiTypography-gutterBottom {
    margin-bottom: 2em;
  }

  .MuiTypography-caption a {
    text-decoration: underline;
    color: #868686;
  }
}

.admin .MuiButton-root.MuiButton-containedPrimary {
  min-width: 246px;
}
